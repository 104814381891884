<template>
    <div class="TestKitList">
        <!--    <div class="TestKitList-form">-->
        <div class="form">
            <el-form ref="tableApple" :inline="true" label-width="100px" :model="formApple" @submit.native.prevent>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="formApple.patientName" clearable placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="手机号" prop="phoneNo">
                            <el-input v-model="formApple.iphoneNo" clearable placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="身份证号" prop="idCardNo">
                            <el-input v-model="formApple.idCardNo" clearable placeholder="请输入身份证号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="填表日期" prop="reagentNo">
                            <el-date-picker v-model="formApple.writeTime" clearable value-format="yyyy-MM-dd"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="SAS结果" prop="questionnaireResult">
                            <el-select v-model="formApple.sasResult" clearable placeholder="请选择问卷风险结果">
                                <el-option v-for="item in sasResultList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="SDS结果" prop="detectResult">
                            <el-select v-model="formApple.sdsResult" clearable placeholder="请选择检测结果">
                                <el-option v-for="item in sdsResultList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="管理医生" prop="inviter">
                            <el-select v-model="formApple.doctor" clearable placeholder="请选择领取地点">
                                <el-option v-for="item in findDoctorList" :key="item.id" :label="item.docName"
                                    :value="item.docName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8" v-if="routerName == 'ColorectalScreening'">
            <el-form-item label="是否填写日期" prop="haveDate">
              <el-select
                v-model="form.haveDate"
                clearable
                placeholder="请选择是否填写日期"
              >
                <el-option
                  v-for="item in haveNullDate_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="问卷类型" prop="FillFormDate">
                            <el-select v-model="formApple.questionType" clearable placeholder="请选择领取地点">
                                <el-option label="健康问卷" value="">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
            <el-form-item label="回收日期" prop="RecoverDate">
              <el-date-picker
                clearable
                value-format="yyyy-MM-dd"
                v-model="form.RecoverDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
                    <el-col :span="24">
                        <el-form-item class="TestKitList-form-button">
                            <el-button @click="onSubmit(formApple)" native-type="submit" type="primary">查询</el-button>
                            <el-button @click="Reset">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <!--    <div class="TestKitList-list">-->
        <div class="list">
            <div class="TestKitList-list-handle">
                <!-- <el-button type="primary" @click="add" size="small">添加</el-button> -->
                <el-button type="primary" @click="exports(formApple)" size="small">导出全部数据</el-button>
                <!-- <el-button type="warning" @click="download" size="small"
          >下载问卷内容模板</el-button
        >
        <el-upload
          class="upload-demo"
          ref="myUpload"
          :action="baseUrl + action_import[routerName]"
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="successUpload"
          :on-error="errorUpload"
          accept=".xlsm"
          :show-file-list="false"
          :on-exceed="handleExceed"
        >
          <el-button size="small" type="success">导入问卷内容</el-button>
        </el-upload>
        <el-button type="warning" @click="downloadDetectResult" size="small"
          >下载检测结果模板</el-button
        >
        <el-upload
          class="upload-demo"
          ref="myUpload_detectResult"
          :action="baseUrl + action_detectResult[routerName]"
          :data="routerName == 'FitScreening' ? {} : { sort: sort[routerName] }"
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="successUpload"
          :on-error="errorUpload"
          accept=".xlsx"
          :show-file-list="false"
          :on-exceed="handleExceed"
        >
          <el-button size="small" type="success">导入检测结果</el-button>
        </el-upload>
        <el-button
          type="warning"
          @click="downloadQuestionnaireResult"
          size="small"
          >下载问卷风险模板</el-button
        >
        <el-upload
          class="upload-demo"
          ref="myUpload"
          :action="baseUrl + action_questionnaireResult[routerName]"
          :data="routerName == 'FitScreening' ? {} : { sort: sort[routerName] }"
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="successUpload"
          :on-error="errorUpload"
          accept=".xlsx"
          :show-file-list="false"
          :on-exceed="handleExceed"
        >
          <el-button size="small" type="success">导入问卷风险结果</el-button>
        </el-upload> -->
            </div>
            <!--      :header-cell-style="headerStyleObj"-->
            <el-table :data="tableApple" v-loading="tableLoad" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                style="width: 100%">
                <el-table-column prop="patientName" align="center" label="姓名">

                    <template slot-scope="scope">
                        <span class="TestKitList-list-blue" @click="detail(scope.row)">{{
                            scope.row.patientName
                            }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="iphoneNo" align="center" label="手机号">
                </el-table-column>
                <el-table-column width="200" prop="idcardNo" align="center" label="身份证号">
                </el-table-column>
                <el-table-column prop="doctorName" align="center" label="管理医生">
                </el-table-column>
                <el-table-column prop="writeTime" align="center" label="填表日期">
                    <template slot-scope="scope">
                        <span>{{ FillTimeTiem(scope.row.writeTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sasScore" align="center" label="SAS分值">
                </el-table-column>
                <el-table-column prop="sasResult" align="center" label="SAS结果">
                </el-table-column>
                <el-table-column prop="sdsScore" align="center" label="SDS分值">
                </el-table-column>
                <el-table-column prop="sdsResult" align="center" label="SDS结果">
                </el-table-column>
                <el-table-column width="200" show-overflow-tooltip prop="remark" align="center" label="备注">

                </el-table-column>
                <el-table-column width="200" prop="detectResult" align="center" label="历史问卷信息">
                    <template slot-scope="scope">
                        <el-link @click="seeClick(scope.row)" type="primary">查看</el-link>
                    </template>
                </el-table-column>
                <!-- <el-table-column
          fixed="right"
          align="center"
          label="操作"
          v-if="routerName != 'FitScreening'"
        >
          <template slot-scope="scope">
            <el-upload
              class="upload-demo"
              ref="myUpload"
              :data="dataPDF(scope.row)"
              :action="baseUrl + action_PDf[routerName]"
              :headers="headers"
              :before-upload="beforeUploadPDF"
              :on-success="successUpload"
              :on-error="errorUpload"
              accept=".pdf"
              :show-file-list="false"
              :on-exceed="handleExceed"
            >
              <el-button size="small" type="text">上传基因报告</el-button>
            </el-upload>
          </template>
        </el-table-column> -->
            </el-table>
            <el-pagination @size-change="handleSizeChangeApple" @current-change="handleCurrentChangeApple"
                :current-page="formApple.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="formApple.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalApple">
            </el-pagination>

        </div>
        <HistoricalQuestionnaire v-model="historical_questionnaire" :list="historical_questionnaireList" :fullscreenLoading="fullscreenLoading">
        </HistoricalQuestionnaire>
    </div>
</template>

<script>
import HistoricalQuestionnaire from '@/components/TestKit/dialog/Historical-questionnaire'
export default {

    name: 'ColorectalScreening',
    components: {
        HistoricalQuestionnaire
    },
    data() {
        return {
            fullscreenLoading: false,
            historical_questionnaire: false,
            historical_questionnaireList: [],
            findDoctorList: [],
            tableApple: [],
            totalApple: 0,
            sasResultList: [
                {
                    label: '无焦虑',
                    value: '无焦虑'
                },
                {
                    label: '轻度焦虑',
                    value: '轻度焦虑'
                },
                {
                    label: '中度焦虑',
                    value: '中度焦虑'
                },
                {
                    label: '重度焦虑',
                    value: '重度焦虑'
                }
            ],
            sdsResultList: [
                {
                    label: '无抑郁',
                    value: '无抑郁'
                },
                {
                    label: '轻度抑郁',
                    value: '轻度抑郁'
                },
                {
                    label: '中度抑郁',
                    value: '中度抑郁'
                },
                {
                    label: '重度抑郁',
                    value: '重度抑郁'
                }
            ],
            formApple: {
                patientName: "",
                iphoneNo: "",
                idCardNo: "",
                writeTime: [],
                sasResult: "",
                sdsResult: "",
                doctor: "",
                questionType: "",
                pageNum: 1,
                pageSize: 10
            },
            form: {
                name: null, // 姓名
                phoneNo: null, // 手机号
                idCardNo: null,
                reagentNo: null, // 试剂盒编号
                FillFormDate: null, // 领取日期
                startFillFormDate: null, // 开始领取日期
                endFillFormDate: null, // 结束领取日期
                RecoverDate: null, // 回收日期
                startRecoverDate: null, // 开始回收日期
                endRecoverDate: null, // 结束回收日期
                detectResult: null, // 检测结果
                questionnaireResult: null, // 问卷结果
                current: 1, // 当前页
                pageSize: 10, // 页条数
                haveDate: null, // 日期为空
                inviter: null, // 领取地点
            },
            haveNullDate_list: [
                {
                    label: '是',
                    value: '1',
                },
                {
                    label: '否',
                    value: '0',
                },
            ],
            questionnaireResult_list: [
                {
                    label: '无结果',
                    value: '0',
                },
                {
                    label: '低级',
                    value: '低级',
                },
                {
                    label: '中级',
                    value: '中级',
                },
                {
                    label: '高级',
                    value: '高级',
                },
            ],
            detectResult_list: [
                {
                    label: '无结果',
                    value: '0',
                },
                {
                    label: '阴性',
                    value: '阴性',
                },
                {
                    label: '阳性',
                    value: '阳性',
                },
                {
                    label: '不合格',
                    value: '不合格',
                },
            ],
            inviter_list: [
                {
                    label: '南开医院全科',
                    value: '南开医院',
                },
                {
                    label: '光大银行',
                    value: '光大银行',
                },
                {
                    label: '中信银行',
                    value: '中信银行',
                },
                {
                    label: '中国银行',
                    value: '中国银行',
                },
                {
                    label: '御锦健康',
                    value: '御锦健康',
                },
            ],
            inviter_list_shi: [
                {
                    label: '石氏医院',
                    value: '石氏医院',
                },
                {
                    label: '济兴医院',
                    value: '济兴医院',
                },
            ],
            inviter_list_fit: [
                {
                    label: '南开治未病中心',
                    value: '南开治未病中心',
                },
                {
                    label: '御锦健康',
                    value: '御锦健康',
                },
            ],
            table: [],
            tableLoad: false,
            total: 0,
            //table头部样式
            headerStyleObj: {
                'background-color': 'rgb(248, 248, 248) !important',
                'font-weight': '600',
                color: 'rgb(103, 103, 103)',
            },
            // action
            baseUrl: process.env.VUE_APP_APIURL,
            headers: {
                authorization: this.$store.state.token,
            },
            // 导入问卷内容
            action_import: {
                ColorectalScreening: 'tempReagent/importNormal',
                TestKitScreening: 'tempReagent/importShi',
                FitScreening: 'tempReagent/importFit',
            },
            // 导入问卷风险结果
            action_questionnaireResult: {
                ColorectalScreening: 'tempReagent/importNormal/questionnaireResult',
                TestKitScreening: 'tempReagent/importNormal/questionnaireResult',
                FitScreening: 'tempReagent/importFit/questionnaireResult',
            },
            // 导入试剂盒检测结果
            action_detectResult: {
                ColorectalScreening: 'tempReagent/importNormal/detectResult',
                TestKitScreening: 'tempReagent/importNormal/detectResult',
                FitScreening: 'tempReagent/importFit/detectResult',
            },
            // 上传基因报告
            action_PDf: {
                ColorectalScreening: 'tempReagent/normalList/upload',
                TestKitScreening: 'tempReagent/normalList/upload',
            },
            // 导出全部数据
            exportApi: {
                ColorectalScreening: 'tempReagent/export',
                TestKitScreening: 'tempReagent/export',
                FitScreening: 'tempReagent/exportFit',
            },
            // 下载问卷内容模板
            templateApi: {
                ColorectalScreening: 'tempReagent/templateNormal',
                TestKitScreening: 'tempReagent/templateNormal',
                FitScreening: 'tempReagent/templateFit',
            },
            // 下载风险结果模板
            questionApi: {
                ColorectalScreening: 'tempReagent/template/normalQuestionnaireResult',
                TestKitScreening: 'tempReagent/template/normalQuestionnaireResult',
                FitScreening: 'tempReagent/template/fitQuestionnaireResult',
            },
            // 下载检测结果模板
            detectApi: {
                ColorectalScreening: 'tempReagent/template/normalDetectResult',
                TestKitScreening: 'tempReagent/template/normalDetectResult',
                FitScreening: 'tempReagent/template/fitDetectResult',
            },
            // 获取列表
            listApi: {
                ColorectalScreening: 'tempReagent/list',
                TestKitScreening: 'tempReagent/list',
                FitScreening: 'tempReagent/fitList',
            },
            sort: {
                ColorectalScreening: 'normal',
                TestKitScreening: 'shi',
                FitScreening: 'fit',
            },
        }
    },
    computed: {
        routerName() {
            return this.$route.name
        },
    },
    watch: {
        // routerName(n, o) {
        //   if (!this.listApi[n]) {
        //     return
        //   }
        //   if (o == n + 'Details' || o == n + 'Add') {
        //     this.getList(this.form)
        //   } else {
        //     this.total = 0
        //     this.table = []
        //     this.Reset()
        //   }
        // },
    },
    filters: {
        getDict(value, list) {
            return (list.find((v) => v.value == value) || {}).label || value
        },
    },
    // beforeRouteEnter(to, form, next) {
    //   next((vm) => {
    //     if (form.path === '/ColorectalScreeningDetails') {
    //       vm.getList(vm.form)
    //     }
    //   })
    // },
    created() {
        console.log(this.$route)
        this.findDoctor()
        this.getListApple()
    },
    methods: {

        FillTimeTiem(dateString) {
            const year = dateString.substring(0, 4);
            const month = dateString.substring(4, 6);
            const day = dateString.substring(6, 8);

            // 格式化为 YYYY-MM-DD
            return `${year}-${month}-${day}`;
        },

        findDoctor() {
            this.$get('hh/polypInfo/findDoctorList').then((res) => {
                console.log(res, '医生下拉');
                this.findDoctorList = res.data
            })
        },




        seeClick(row) {
            // console.log(row);
            this.historical_questionnaire = true

            this.fullscreenLoading = true
            this.$get(`hh/polypInfo/findAll?openId=${row.patientOpenid}`).then((res) => {
                console.log(res);
                let arr = []
                let keyArr = Object.keys(res.data)
                let valueArr = Object.values(res.data)
                console.log(keyArr);
                console.log(valueArr);
                keyArr.forEach((item, index) => {
                    let obj = {
                        QuestionnaireName: '健康问卷',
                        FillTime: item,
                        SASvalue: valueArr[index][3].score,
                        SASresult: valueArr[index][3].scoreExplain,
                        SDSvalue: valueArr[index][2].score,
                        SDSresult: valueArr[index][2].scoreExplain,
                        remarks: valueArr[index][0].comment,
                        patientOpenid: row.patientOpenid,
                    }
                    arr.push(obj)
                })
                this.historical_questionnaireList = arr
                this.fullscreenLoading = false
            })
        },




        onSubmit() {
            this.formApple.pageNum = 1
            this.getListApple()
        },
        Reset() {
            this.$refs['tableApple'].resetFields()
            this.formApple = {
                patientName: "",
                iphoneNo: "",
                idCardNo: "",
                writeTime: [],
                sasResult: "",
                sdsResult: "",
                doctor: "",
                questionType: "",
                pageNum: 1,
                pageSize: 10
            }

            this.$nextTick(() => {
                this.getListApple()

            })
        },
        getListApple() {
            this.$post('hh/polypInfo/findQuestionnaireList', this.formApple).then((res) => {
                console.log(res, '列表');
                this.tableApple = res.data.list
                this.totalApple = res.data.total
            })
        },


        handleCurrentChangeApple(e) {
            this.formApple.pageNum = e
            this.getListApple()
        },
        handleSizeChangeApple(e) {
            this.formApple.pageSize = e
            this.getListApple()

        },















        dataPDF(e) {
            let obj = {
                reagentId: e.reagentId,
            }
            return obj
        },
        exports() {

            let obj = {
                patientName: this.formApple.patientName,
                iphoneNo: this.formApple.iphoneNo,
                idCardNo: this.formApple.idCardNo,
                writeTime: this.formApple.writeTime,
                sasResult: this.formApple.sasResult,
                sdsResult: this.formApple.sdsResult,
                doctor: this.formApple.doctor,
                questionType: this.formApple.questionType,
            }
            this.$FilePost('hh/polypInfo/exportQuestionnaireList', obj).then((res) => {
                console.log(res);
            })

            // if (y.FillFormDate != null && y.FillFormDate.length != 0) {
            //     y.startFillFormDate = y.FillFormDate[0]
            //     y.endFillFormDate = y.FillFormDate[1]
            // } else {
            //     y.startFillFormDate = null
            //     y.endFillFormDate = null
            // }

            // if (y.RecoverDate != null && y.RecoverDate.length != 0) {
            //     y.startRecoverDate = y.RecoverDate[0]
            //     y.endRecoverDate = y.RecoverDate[1]
            // } else {
            //     y.startRecoverDate = null
            //     y.endRecoverDate = null
            // }

            // this.$file(this.exportApi[this.routerName], y).then((res) => {
            //     console.log(res)
            // })
        },
        beforeUploadPDF(file) {
            let extension = file.name.split('.')[1]
            let extensionList = ['pdf', 'PDF']

            if (extensionList.indexOf(extension) < 0) {
                this.$message.warning('只能上传pdf文件')
                return false
            }
        },
        beforeUpload(file) {
            let extension = file.name.split('.')[1]
            let extensionList = ['xlsx', 'xlsm']

            if (extensionList.indexOf(extension) < 0) {
                this.$message.warning('只能上传xlsx文件')
                return false
            }
        },
        successUpload(res) {
            console.log(res)
            if (res.code === 'SUCCESS') {
                this.$message.success(res.message)
                this.getList(this.form)
            } else {
                this.$message.error(res.message)
            }
        },
        errorUpload(err) {
            this.$message.error(JSON.parse(err.message))
        },
        handleExceed(files, fileList) {
            console.log(files, fileList)
        },
        // 下载问卷内容模板
        download() {
            this.$file(this.templateApi[this.routerName]).then((res) => {
                console.log(res)
            })
        },
        // 下载风险结果模板
        downloadQuestionnaireResult() {
            this.$file(this.questionApi[this.routerName]).then((res) => {
                console.log(res)
            })
        },
        // 下载检测结果模板
        downloadDetectResult() {
            this.$file(this.detectApi[this.routerName]).then((res) => {
                console.log(res)
            })
        },

        getList(y) {
            if (y.FillFormDate != null && y.FillFormDate.length != 0) {
                y.startFillFormDate = y.FillFormDate[0]
                y.endFillFormDate = y.FillFormDate[1]
            } else {
                y.startFillFormDate = null
                y.endFillFormDate = null
            }

            if (y.RecoverDate != null && y.RecoverDate.length != 0) {
                y.startRecoverDate = y.RecoverDate[0]
                y.endRecoverDate = y.RecoverDate[1]
            } else {
                y.startRecoverDate = null
                y.endRecoverDate = null
            }
            y.sort = this.sort[this.routerName]

            this.$post(this.listApi[this.routerName], y).then((res) => {
                this.total = res.data.total
                this.table = res.data.list
            })
        },
        add() {
            this.$router.push({
                path: '/' + this.routerName + 'Add',
                query: {
                    // state: this.sort[this.routerName],
                },
            })
        },
        detail(y) {
            console.log(this.routerName);
            this.$router.push({
                path: '/' + this.routerName + 'Detail',
                query: {
                    reagentId: y.patientOpenid,
                    // state: this.sort[this.routerName],
                },
            })
        },
        del(y) {
            this.$del('tempReagent/remove', y.reagentId).then((res) => {
                console.log(res)

                if (res.data.code === 200) {
                    this.form.current = 1
                    this.getList(this.form)
                    this.$message.success(res.data.message)
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleSizeChange(e) {
            this.form.pageSize = e
            this.getList(this.form)
        },
        handleCurrentChange(e) {
            this.form.current = e
            this.getList(this.form)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../style/TestKit/TestKitList';
</style>
